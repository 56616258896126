<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>价值包参数配置</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="goBacks">配置策略</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="goBack">配置规则</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>新增价值包规则</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <a-form :form="form" class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="价值包类型">
              <a-select
                v-if="!policyId"
                v-model="policyTypeId"
                allow-clear
                @change="chooseValueType"
              >
                <a-select-option
                  v-for="item in tblData"
                  :key="item.policyTypeId"
                >
                  {{ item.policyTypeName }}
                </a-select-option>
              </a-select>
              <span v-else>
                {{ info.policyTypeValue }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-tabs v-if="showTabs" :default-active-key="tabsValue">
          <a-tab-pane key="1" tab="加价规则" :disabled="canAddValue">
            <div class="rule__hint">
              <a-icon type="info-circle" />
              <span>
                加价/计提标准在加价规则和兑付规则里信息通用，一边修改，另一个规则里同步修改。
              </span>
            </div>
            <div class="rule__title">
              <span class="red__tag">*</span>
              加价标准（允许运营中心配置）
            </div>
            <div>
              <a-row>
                <a-col :span="8">
                  <a-radio-group v-model="chooseAddType">
                    <a-radio
                      value="AppendTypeFixed"
                      :disabled="isAddFixation"
                      style="margin-bottom: 12px"
                    >
                      <div class="fixation__width">
                        按固定金额（元/台）
                      </div>
                      <a-input-number
                        v-model="addFixationNum"
                        :disabled="
                          isAddFixation || chooseAddType === 'AppendTypeRatio'
                        "
                      ></a-input-number>
                    </a-radio>
                    <a-radio value="AppendTypeRatio" :disabled="isAddRatio">
                      <div class="fixation__width">
                        按比例（基于平台价%）
                      </div>
                      <a-input-number
                        :max="100"
                        v-model="addRatioNum"
                        :disabled="
                          isAddRatio || chooseAddType === 'AppendTypeFixed'
                        "
                      ></a-input-number>
                      <a-checkbox
                        v-model="chooseAddRatio"
                        :disabled="
                          isAddRatio || chooseAddType === 'AppendTypeFixed'
                        "
                      >
                        最大不超过（元）
                        <a-input-number
                          v-model="addRatioNumMax"
                          :disabled="
                            isAddRatio ||
                              chooseAddType === 'AppendTypeFixed' ||
                              !chooseAddRatio
                          "
                        ></a-input-number>
                      </a-checkbox>
                    </a-radio>
                  </a-radio-group>
                </a-col>
              </a-row>
            </div>
          </a-tab-pane>
          <a-tab-pane
            key="2"
            tab="兑付规则"
            force-render
            :disabled="canChooseRule"
          >
            <div class="rule__hint">
              <a-icon type="info-circle" />
              <span>
                加价/计提标准在加价规则和兑付规则里信息通用，一边修改，另一个规则里同步修改。
              </span>
            </div>
            <div class="rule__title">
              <span class="red__tag">*</span>
              计提标准（允许运营中心配置）
            </div>
            <div class="rule__radio">
              <a-radio-group v-model="isRiding" disabled>
                <a-radio :value="0" style="margin-right: 24px">按销量</a-radio>
                <a-radio :value="1">按骑行里程</a-radio>
              </a-radio-group>
            </div>
            <div v-if="!isRiding">
              <a-row>
                <a-col :span="8">
                  <a-radio-group v-model="chooseAddType">
                    <a-radio
                      value="AppendTypeFixed"
                      :disabled="true"
                      style="margin-bottom: 12px"
                    >
                      <div class="fixation__width">
                        按固定金额（元/台）
                      </div>
                      <a-input-number
                        v-model="addFixationNum"
                        :disabled="true"
                      ></a-input-number>
                    </a-radio>
                    <a-radio value="AppendTypeRatio" :disabled="true">
                      <div class="fixation__width">
                        按比例（基于平台价%）
                      </div>
                      <a-input-number
                        :max="100"
                        v-model="addRatioNum"
                        :disabled="true"
                      ></a-input-number>
                      <a-checkbox v-model="chooseAddRatio" :disabled="true">
                        最大不超过（元）
                        <a-input-number
                          v-model="addRatioNumMax"
                          :disabled="true"
                        ></a-input-number>
                      </a-checkbox>
                    </a-radio>
                  </a-radio-group>
                </a-col>
              </a-row>
            </div>
            <div v-else>
              <a-row
                v-for="(item, index) in ridingList"
                :key="item.policyDetailId"
                class="row"
              >
                <div class="item__left">
                  <span
                    class="important"
                    :style="index === 0 ? { visibility: 'hidden' } : ''"
                  >
                    ELSE
                  </span>
                  <div class="item__detail">
                    当天骑行公里数
                  </div>
                  <span>>=</span>
                  <a-input-number
                    :min="0"
                    v-model="item.leftValue"
                  ></a-input-number>
                </div>
                <div class="item__right">
                  <div class="item__detail">
                    每车每天补贴（元）
                  </div>
                  <a-input-number
                    :min="0"
                    v-model="item.drawNumber"
                  ></a-input-number>
                </div>
                <div>
                  <a-icon
                    v-if="ridingList.length > 1"
                    style="color: red"
                    type="close"
                    @click="deleteRiding(index)"
                  />
                </div>
              </a-row>
              <div class="item__addIcon" @click="addRiding">
                <a-icon type="plus-square" theme="twoTone" class="addIcon" />
                增加条件
              </div>
              <a-row>
                <a-col>
                  <div class="rule__category" style="color: transparent">
                    次数要求
                  </div>
                  <a-checkbox v-model="chooseMaxCarCount">
                    <div class="rule__detail">
                      最大车辆数限制
                    </div>
                    <a-input-number
                      :min="0"
                      :precision="0"
                      v-model="maxCarCount"
                      :disabled="!chooseMaxCarCount"
                    ></a-input-number>
                  </a-checkbox>
                </a-col>
              </a-row>
            </div>
            <div class="rule__title">
              <span class="red__tag">*</span>
              任务周期
            </div>
            <a-row>
              <a-select
                v-model="taskPeriod"
                style="margin-bottom: 24px"
                :options="taskOptions"
                allow-clear
              ></a-select>
            </a-row>
            <div>
              <a-row>
                <a-col>
                  <div class="rule__category">地域要求</div>
                  <a-checkbox v-model="chooseTerritory" :disabled="isTerritory">
                    <div class="rule__detail">
                      最少地域单位
                    </div>
                    <a-select
                      v-model="chooseTerritoryItem"
                      :options="areaOptions"
                      allow-clear
                      :disabled="isTerritory || !chooseTerritory"
                    ></a-select>
                  </a-checkbox>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <div class="rule__category">次数要求</div>
                  <a-checkbox v-model="chooseDegree" :disabled="isDegree">
                    <div class="rule__detail">
                      次数不少于
                    </div>
                    <a-input-number
                      :min="0"
                      :precision="0"
                      v-model="degreeNum"
                      :disabled="isDegree || !chooseDegree"
                    ></a-input-number>
                  </a-checkbox>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <div class="rule__category">人数要求</div>
                  <a-checkbox
                    v-model="choosePeopleOnceMin"
                    :disabled="isPeople"
                  >
                    <div class="rule__detail">
                      单次用户数不少于
                    </div>
                    <a-input-number
                      :min="0"
                      :precision="0"
                      v-model="peopleOnceMin"
                      :disabled="isPeople || !choosePeopleOnceMin"
                    ></a-input-number>
                  </a-checkbox>
                  <a-checkbox
                    v-model="choosePeopleTodayMin"
                    :disabled="isPeople"
                  >
                    <div class="rule__detail">
                      当天用户数不少于
                    </div>
                    <a-input-number
                      :min="0"
                      :precision="0"
                      v-model="peopleTodayMin"
                      :disabled="isPeople || !choosePeopleTodayMin"
                    ></a-input-number>
                  </a-checkbox>
                  <a-checkbox
                    v-model="choosePeopleTotalMin"
                    :disabled="isPeople"
                  >
                    <div class="rule__detail">
                      总计用户数不少于
                    </div>
                    <a-input-number
                      :min="0"
                      :precision="0"
                      v-model="peopleTotalMin"
                      :disabled="isPeople || !choosePeopleTotalMin"
                    ></a-input-number>
                  </a-checkbox>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <div class="rule__category">骑行要求</div>
                  <a-checkbox
                    v-model="chooseRidingOnceMin"
                    :disabled="idRiding"
                  >
                    <div class="rule__detail">
                      单次公里数不少于
                    </div>
                    <a-input-number
                      :min="0"
                      v-model="ridingOnceMin"
                      :disabled="idRiding || !chooseRidingOnceMin"
                    ></a-input-number>
                  </a-checkbox>
                  <a-checkbox
                    v-model="chooseRidingTodayMin"
                    :disabled="idRiding"
                  >
                    <div class="rule__detail">
                      当天公里数不少于
                    </div>
                    <a-input-number
                      :min="0"
                      v-model="ridingTodayMin"
                      :disabled="idRiding || !chooseRidingTodayMin"
                    ></a-input-number>
                  </a-checkbox>
                  <a-checkbox
                    v-model="chooseRidingTotalMin"
                    :disabled="idRiding"
                  >
                    <div class="rule__detail">
                      总计公里数不少于
                    </div>
                    <a-input-number
                      :min="0"
                      v-model="ridingTotalMin"
                      :disabled="idRiding || !chooseRidingTotalMin"
                    ></a-input-number>
                  </a-checkbox>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <div class="rule__category">任务要求</div>
                  <a-checkbox v-model="chooseTask" :disabled="isTask">
                    <div class="rule__detail">
                      完成率不小于（%）
                    </div>
                    <a-input-number
                      :min="0"
                      :max="100"
                      v-model="taskMin"
                      :disabled="isTask || !chooseTask"
                    ></a-input-number>
                  </a-checkbox>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <div class="rule__category">销售要求</div>
                  <a-checkbox v-model="chooseMarket" :disabled="isMarket">
                    <div class="rule__detail">
                      完成率不小于（%）
                    </div>
                    <a-input-number
                      :min="0"
                      :max="100"
                      v-model="marketMin"
                      :disabled="isMarket || !chooseMarket"
                    ></a-input-number>
                  </a-checkbox>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <div class="rule__category">车辆要求</div>
                  <a-checkbox v-model="carCountRequire" :disabled="!isRent">
                    <div class="rule__detail">
                      试骑车辆数不小于
                    </div>
                    <a-input-number
                      :min="0"
                      :precision="0"
                      v-model="carCount"
                      :disabled="!isRent || !carCountRequire"
                    ></a-input-number>
                  </a-checkbox>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <div class="rule__category">时长要求</div>
                  <a-checkbox v-model="timeDayRequire" :disabled="!isRent">
                    <div class="rule__detail">
                      时长天数不少于
                    </div>
                    <a-input-number
                      :min="0"
                      v-model="timeDayCount"
                      :disabled="!isRent || !timeDayRequire"
                    ></a-input-number>
                  </a-checkbox>
                  <a-checkbox v-model="timeHourRequire" :disabled="!isRent">
                    <div class="rule__detail">
                      时长小时不少于
                    </div>
                    <a-input-number
                      :min="0"
                      v-model="timeHourCount"
                      :disabled="!isRent || !timeHourRequire"
                    ></a-input-number>
                  </a-checkbox>
                </a-col>
              </a-row>
            </div>
            <div class="rule__title">
              <span class="red__tag">*</span>
              计提周期
            </div>
            <a-row>
              <a-col :span="8">
                <a-select
                  v-model="extractPeriod"
                  :options="timeOptions"
                  allow-clear
                ></a-select>
              </a-col>
            </a-row>
            <div class="rule__title">
              <span class="red__tag">*</span>
              兑付方式
            </div>
            <a-row>
              <a-col :span="8">
                <a-select
                  v-model="extractMode"
                  :options="modeOptions"
                  allow-clear
                ></a-select>
              </a-col>
            </a-row>
            <div class="rule__title">
              <span class="red__tag">*</span>
              计提单位
            </div>
            <a-row>
              <a-col :span="8">
                <a-select
                  v-model="extractUnit"
                  :options="institutionOptions"
                  allow-clear
                ></a-select>
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </a-form>
      <div class="footer">
        <a-button type="primary" @click="onSave">提交</a-button>
        <a-button @click="onBack">取消</a-button>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import {
  addPolicyValue,
  fetchCanChoosePolicy,
  fetchStrategyOfPolicyDetail,
  modifyPolicyValue
} from "@/services/ValueBag";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      tblData: [],
      isAddFixation: false,
      isAddRatio: false,
      isRuleFixation: true,
      isRuleRatio: true,
      isTerritory: false,
      isDegree: false,
      isPeople: false,
      info: {},
      idRiding: false,
      isTask: false,
      isMarket: false,
      canAddValue: false,
      canChooseRule: false,
      tabsValue: "1",
      showTabs: true,
      chooseAddType: "AppendTypeFixed",
      chooseAddRatio: false,
      addFixationNum: "",
      addRatioNum: "",
      addRatioNumMax: "",
      chooseTerritory: false,
      chooseDegree: false,
      chooseMaxCarCount: false,
      degreeNum: "",
      maxCarCount: "",
      choosePeopleOnceMin: false,
      peopleOnceMin: "",
      choosePeopleTodayMin: false,
      peopleTodayMin: "",
      choosePeopleTotalMin: false,
      peopleTotalMin: "",
      chooseRidingOnceMin: false,
      ridingOnceMin: "",
      chooseRidingTodayMin: false,
      ridingTodayMin: "",
      chooseRidingTotalMin: false,
      ridingTotalMin: "",
      chooseTask: false,
      taskMin: "",
      chooseMarket: false,
      chooseTerritoryItem: "",
      marketMin: "",
      id: 2,
      isRiding: 0,
      isRent: false,
      carCountRequire: false,
      timeDayRequire: false,
      timeHourRequire: false,
      timeDayCount: "",
      carCount: "",
      timeHourCount: "",
      strategyId: this.$route.params.id,
      policyId: this.$route.params.policyId,
      extractPeriod: "",
      extractMode: "",
      extractUnit: "",
      policyTypeId: "",
      taskPeriod: "",
      taskOptions: [
        { value: "TaskPeriodMonth", label: "按月" },
        { value: "TaskPeriodQuarter", label: "按季" },
        { value: "TaskPeriodYear", label: "按年" }
      ],
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      startTime: "",
      disabled: true,
      endTime: "",
      ridingList: [
        {
          policyDetailId: 1,
          leftValue: "",
          drawNumber: ""
        }
      ],
      areaOptions: [
        { value: "province", label: "省" },
        { value: "city", label: "市" },
        { value: "county", label: "县" }
      ],
      timeOptions: [
        { value: "ExtractPeriodMonth", label: "按月" },
        { value: "ExtractPeriodQuarter", label: "按季" },
        { value: "ExtractPeriodYear", label: "按年" },
        { value: "ExtractPeriodNow", label: "按资金池" }
      ],
      modeOptions: [
        { value: "ExtractModeAuto", label: "自动核销兑付" },
        { value: "ExtractModeManual", label: "手动核销兑付" }
      ],
      institutionOptions: [
        { value: "ExtractUnitOperationCenter", label: "运营中心" },
        { value: "ExtractUnitHQ", label: "运营总部" },
        { value: "ExtractUnitServerCenter", label: "服务中心" },
        { value: "ExtractUnitEndConsumerMarket", label: "渠道终端" }
      ]
    };
  },
  watch: {
    chooseAddType(newValue) {
      if (newValue === "AppendTypeFixed") {
        this.chooseAddRatio = false;
        this.addRatioNum = "";
        this.addRatioNumMax = "";
      } else {
        this.addFixationNum = "";
      }
    },
    chooseAddRatio(newValue) {
      if (!newValue) {
        this.addRatioNumMax = "";
      }
    },
    chooseTerritory(newValue) {
      if (!newValue) {
        this.chooseTerritoryItem = "";
      }
    },
    chooseDegree(newValue) {
      if (!newValue) {
        this.degreeNum = "";
      }
    },
    chooseMaxCarCount(newValue) {
      if (!newValue) {
        this.maxCarCount = "";
      }
    },
    choosePeopleOnceMin(newValue) {
      if (!newValue) {
        this.peopleOnceMin = "";
      }
    },
    choosePeopleTodayMin(newValue) {
      if (!newValue) {
        this.peopleTodayMin = "";
      }
    },
    choosePeopleTotalMin(newValue) {
      if (!newValue) {
        this.peopleTotalMin = "";
      }
    },
    chooseRidingOnceMin(newValue) {
      if (!newValue) {
        this.ridingOnceMin = "";
      }
    },
    chooseRidingTodayMin(newValue) {
      if (!newValue) {
        this.ridingTodayMin = "";
      }
    },
    chooseRidingTotalMin(newValue) {
      if (!newValue) {
        this.ridingTotalMin = "";
      }
    },
    chooseTask(newValue) {
      if (!newValue) {
        this.taskMin = "";
      }
    },
    chooseMarket(newValue) {
      if (!newValue) {
        this.marketMin = "";
      }
    },
    carCountRequire(newValue) {
      if (!newValue) {
        this.carCount = "";
      }
    },
    timeDayRequire(newValue) {
      if (!newValue) {
        this.timeDayCount = "";
      }
    },
    timeHourRequire(newValue) {
      if (!newValue) {
        this.timeHourCount = "";
      }
    }
  },
  mounted() {
    this.onLoadPolicy();
    if (this.policyId) {
      this.onDetail();
    }
  },
  methods: {
    // 返回上一层
    onBack() {
      this.$router.go(-1);
    },
    // 详情内容
    onDetail() {
      fetchStrategyOfPolicyDetail(this.policyId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.ridingList = data.detailVoList;
          this.info = resp.data.data;
          let maxValue = 0;
          this.ridingList.forEach(item => {
            if (item.policyDetailId > maxValue) {
              maxValue = item.policyDetailId;
            }
          });
          this.id = maxValue + 1;
          this.addFixationNum = data.appendFixedValue;
          this.addRatioNumMax = data.appendMaxValue;
          this.addRatioNum = data.appendRatio;
          this.chooseAddType = data.appendType;

          this.carCountRequire = data.carCountRequire;
          this.carCount = data.carCount;
          this.timeDayRequire = data.timeDayRequire;
          this.timeDayCount = data.timeDayCount;
          this.timeHourRequire = data.timeHourRequire;
          this.timeHourCount = data.timeHourCount;

          this.chooseTerritoryItem = data.areaValue;
          this.chooseTerritory = data.areaRequire;
          this.addFixationNum = data.drawFixedValue;
          this.maxCarCount = data.maxCarCount;
          if (this.maxCarCount) {
            this.chooseMaxCarCount = true;
          }
          this.addRatioNumMax = data.drawMaxValue;
          if (this.addRatioNumMax) {
            this.chooseAddRatio = true;
          }
          this.addRatioNum = data.drawRatio;
          this.extractMode = data.extractMode;
          this.extractPeriod = data.extractPeriod;
          this.extractUnit = data.extractUnit;
          this.degreeNum = data.numberCount;
          this.chooseDegree = data.numberRequire;
          this.ridingOnceMin = data.onceRideCount;
          this.chooseRidingOnceMin = data.onceRideRequire;
          this.peopleOnceMin = data.onceUserCount;
          this.choosePeopleOnceMin = data.onceUserRequire;
          this.policyTypeId = data.policyTypeId;
          this.marketMin = data.salesCount;
          this.chooseMarket = data.salesRequire;
          this.strategyId = data.strategyId;
          this.taskMin = data.taskCount;
          this.taskPeriod = data.taskPeriod;
          this.chooseTask = data.taskRequire;
          this.ridingTodayMin = data.todayRideCount;
          this.chooseRidingTodayMin = data.todayRideRequire;
          this.peopleTodayMin = data.todayUserCount;
          this.choosePeopleTodayMin = data.todayUserRequire;
          this.chooseRidingTotalMin = data.totalRideRequire;
          this.ridingTotalMin = data.totalRideCount;
          this.peopleTotalMin = data.totalUserCount;
          this.choosePeopleTotalMin = data.totalUserRequire;
          this.beforeChangeType(this.policyTypeId);
        }
      });
    },
    // 加载价值包类型
    onLoadPolicy() {
      fetchCanChoosePolicy(this.strategyId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.tblData = resp.data.data;
          this.tblData = this.tblData.filter(item => {
            return item.policyTypeId !== "SalesAdded";
          });
          this.tblData.forEach(item => {
            if (!item.allowSet) {
              item.allowSets = "允许";
            } else {
              item.allowSets = "不允许";
            }
          });
        }
      });
    },
    // 切换价值包时，页面转换
    chooseValueType(value) {
      this.extractUnit = "";
      this.ridingList = [
        {
          policyDetailId: 1,
          leftValue: "",
          drawNumber: ""
        }
      ];
      this.isRiding = 0;
      if (value) {
        this.isAddFixation = false;
        this.isAddRatio = false;
        this.isRuleFixation = true;
        this.isRuleRatio = true;
        this.isTerritory = false;
        this.isDegree = false;
        this.isPeople = false;
        this.idRiding = false;
        this.isTask = false;
        this.isMarket = false;
        this.chooseAddType = "AppendTypeFixed";
        this.chooseAddRatio = false;
        this.addFixationNum = "";
        this.addRatioNum = "";
        this.addRatioNumMax = "";
        this.chooseTerritory = false;
        this.chooseMaxCarCount = false;
        this.chooseDegree = false;
        this.degreeNum = "";
        this.maxCarCount = "";
        this.choosePeopleOnceMin = false;
        this.peopleOnceMin = "";
        this.choosePeopleTodayMin = false;
        this.peopleTodayMin = "";
        this.choosePeopleTotalMin = false;
        this.peopleTotalMin = "";
        this.chooseRidingOnceMin = false;
        this.ridingOnceMin = "";
        this.chooseRidingTodayMin = false;
        this.ridingTodayMin = "";
        this.chooseRidingTotalMin = false;
        this.ridingTotalMin = "";
        this.chooseTask = false;
        this.taskMin = "";
        this.chooseMarket = false;
        this.chooseTerritoryItem = "";
        this.marketMin = "";
        this.isRent = false;
        this.carCountRequire = false;
        this.carCount = "";
        this.timeDayRequire = false;
        this.timeDayCount = "";
        this.timeHourRequire = false;
        this.timeHourCount = "";
      }
      if (
        value === "RidingActivity" ||
        value === "RidingChannelExtract" ||
        value === "RidingServiceExtract" ||
        value === "LeaseActivity"
      ) {
        this.isRent = true;
      }
      if (value === "RidingServiceExtract") {
        this.extractUnit = "ExtractUnitServerCenter";
      }
      if (value === "RidingChannelExtract") {
        this.extractUnit = "ExtractUnitEndConsumerMarket";
      }
      if (
        value === "RidingChannelExtract" ||
        value === "RidingServiceExtract"
      ) {
        this.isRiding = 1;
        this.canAddValue = true;
        this.canChooseRule = false;
        this.tabsValue = "2";
        this.showTabs = false;
        this.isTask = true;
        this.isMarket = true;
        this.$nextTick(() => {
          this.showTabs = true;
        });
      } else if (
        value === "WarehouseAppend" ||
        value === "Accretion" ||
        value === "Sales"
      ) {
        this.canChooseRule = true;
        this.canAddValue = false;
        this.tabsValue = "1";
        this.showTabs = false;
        this.$nextTick(() => {
          this.showTabs = true;
        });
      } else {
        this.isTask = false;
        this.isMarket = false;
        this.canAddValue = false;
        this.canChooseRule = false;
        this.showTabs = false;
        this.$nextTick(() => {
          this.showTabs = true;
        });
      }
      if (value === "LeaseActivity") {
        this.isTask = true;
        this.isMarket = true;
      }
      if (value === "RidingActivity") {
        this.isTask = true;
        this.isMarket = true;
      }
      if (value === "ChannelAward") {
        this.isTerritory = true;
        this.isDegree = true;
        this.isPeople = true;
        this.idRiding = true;
        this.isTask = true;
      }
    },
    beforeChangeType(value) {
      if (
        value === "RidingActivity" ||
        value === "RidingChannelExtract" ||
        value === "RidingServiceExtract" ||
        value === "LeaseActivity"
      ) {
        this.isRent = true;
      }
      if (value === "RidingServiceExtract") {
        this.extractUnit = "ExtractUnitServerCenter";
      }
      if (value === "RidingChannelExtract") {
        this.extractUnit = "ExtractUnitEndConsumerMarket";
      }
      if (
        value === "RidingChannelExtract" ||
        value === "RidingServiceExtract"
      ) {
        this.isRiding = 1;
        this.canAddValue = true;
        this.canChooseRule = false;
        this.tabsValue = "2";
        this.showTabs = false;
        this.isTask = true;
        this.isMarket = true;
        this.$nextTick(() => {
          this.showTabs = true;
        });
      } else if (
        value === "WarehouseAppend" ||
        value === "Sales" ||
        value === "Accretion"
      ) {
        this.canChooseRule = true;
        this.canAddValue = false;
        this.tabsValue = "1";
        this.showTabs = false;
        this.$nextTick(() => {
          this.showTabs = true;
        });
      } else {
        this.isTask = false;
        this.isMarket = false;
        this.canAddValue = false;
        this.canChooseRule = false;
        this.showTabs = false;
        this.$nextTick(() => {
          this.showTabs = true;
        });
      }
      if (value === "LeaseActivity") {
        this.isTask = true;
        this.isMarket = true;
      }
      if (value === "RidingActivity") {
        this.isTask = true;
        this.isMarket = true;
      }
      if (value === "ChannelAward") {
        this.isTerritory = true;
        this.isDegree = true;
        this.isPeople = true;
        this.idRiding = true;
        this.isTask = true;
      }
    },
    // 新增骑行里程条件
    addRiding() {
      this.ridingList.push({
        leftValue: "",
        drawNumber: "",
        policyDetailId: this.id
      });
      this.id += this.id;
    },
    // 删除接口
    deleteRiding(index) {
      const that = this;
      that.$confirm({
        title: "确定要删除吗?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          that.ridingList.splice(index, 1);
        }
      });
    },
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    // 返回上两页
    goBacks() {
      this.$router.go(-2);
    },
    // 保存
    onSave() {
      if (!this.policyTypeId) {
        this.$message.warning("请选择价值包类型");
        return;
      }
      if (!this.canChooseRule) {
        if (!this.taskPeriod) {
          this.$message.warning("请选择任务周期");
          return;
        }
        if (!this.extractPeriod) {
          this.$message.warning("请选择计提周期");
          return;
        }
        if (!this.extractMode) {
          this.$message.warning("请选择兑付方式");
          return;
        }
        if (!this.extractUnit) {
          this.$message.warning("请选择计提单位");
          return;
        }
      }
      if (
        this.chooseAddType === "AppendTypeFixed" &&
        this.canAddValue === false
      ) {
        if (this.addFixationNum === "") {
          this.$message.warning("按固定金额未填入");
          return;
        }
      } else if (
        this.chooseAddType === "AppendTypeRatio" &&
        this.canAddValue === false
      ) {
        if (!this.addRatioNum) {
          this.$message.warning("按比例（基于基础价%）未填入");
          return;
        }
        if (this.chooseAddRatio) {
          if (this.addRatioNumMax === "") {
            this.$message.warning("最大不超过（元）未填入");
            return;
          }
        }
      }
      if (this.chooseTerritory) {
        if (!this.chooseTerritoryItem) {
          this.$message.warning("最少地域单位未选择");
          return;
        }
      }
      if (this.chooseDegree) {
        if (!this.degreeNum) {
          this.$message.warning("次数不少于未填入");
          return;
        }
      }
      if (this.choosePeopleOnceMin) {
        if (!this.peopleOnceMin) {
          this.$message.warning("单次用户数不少于未填入");
          return;
        }
      }
      if (this.choosePeopleTodayMin) {
        if (!this.peopleTodayMin) {
          this.$message.warning("当天用户数不少于未填入");
          return;
        }
      }
      if (this.choosePeopleTotalMin) {
        if (!this.peopleTotalMin) {
          this.$message.warning("总计用户数不少于未填入");
          return;
        }
      }
      if (this.chooseRidingOnceMin) {
        if (!this.ridingOnceMin) {
          this.$message.warning("单次公里数不少于未填入");
          return;
        }
      }
      if (this.chooseRidingTodayMin) {
        if (!this.ridingTodayMin) {
          this.$message.warning("当天公里数不少于未填入");
          return;
        }
      }
      if (this.chooseRidingTotalMin) {
        if (!this.ridingTotalMin) {
          this.$message.warning("总计公里数不少于未填入");
          return;
        }
      }
      if (this.chooseTask) {
        if (!this.taskMin) {
          this.$message.warning("完成率不小于（%）未填入");
          return;
        }
      }

      if (this.carCountRequire) {
        if (!this.carCount) {
          this.$message.warning("车辆数量不少于未填入");
          return;
        }
      }

      if (this.timeDayRequire) {
        if (!this.timeDayCount) {
          this.$message.warning("时长天数不少于未填入");
          return;
        }
      }

      if (this.timeHourRequire) {
        if (!this.timeHourCount) {
          this.$message.warning("时长小时不少于未填入");
          return;
        }
      }

      if (this.chooseMarket) {
        if (!this.marketMin) {
          this.$message.warning("完成率不小于（%）未填入的");
          return;
        }
      }

      if (this.isRiding) {
        let hasAllRide = true;
        this.ridingList.forEach((item, index) => {
          if (!item.leftValue || !item.drawNumber) {
            hasAllRide = false;
          }
          item.policyId = this.policyId;
          if (index + 1 < this.ridingList.length) {
            item.rightValue = this.ridingList[index + 1].leftValue;
          } else {
            item.rightValue = null;
          }
        });
        if (!this.policyId) {
          this.ridingList.forEach(item => {
            item.policyDetailId = null;
          });
        }
        if (!hasAllRide) {
          this.$message.warning("请填写完整计提标准");
          return;
        }
        if (this.chooseMaxCarCount) {
          if (!this.maxCarCount) {
            this.$message.warning("最大车辆数限制未填入的");
            return;
          }
        }
      }
      if (!this.isRiding) {
        this.ridingList = [];
      }
      const params = {
        detailFormList: this.ridingList,
        appendFixedValue: this.addFixationNum,
        appendMaxValue: this.addRatioNumMax,
        appendRatio: this.addRatioNum,
        appendType:
          this.chooseAddType === "AppendTypeFixed"
            ? "AppendTypeFixed"
            : "AppendTypeRatio",
        areaValue: this.chooseTerritoryItem,
        areaRequire: this.chooseTerritory,
        drawFixedValue: this.addFixationNum,
        maxCarCount: this.maxCarCount,
        drawMaxValue: this.addRatioNumMax,
        drawRatio: this.addRatioNum,
        drawType:
          this.chooseAddType === "AppendTypeFixed"
            ? "DrawTypeFixed"
            : "DrawTypeRatio",
        extractMode: this.extractMode,
        extractPeriod: this.extractPeriod,
        extractUnit: this.extractUnit,
        numberCount: this.degreeNum,
        numberRequire: this.chooseDegree,
        onceRideCount: this.ridingOnceMin,
        onceRideRequire: this.chooseRidingOnceMin,
        onceUserCount: this.peopleOnceMin,
        onceUserRequire: this.choosePeopleOnceMin,
        policyTypeId: this.policyTypeId,
        salesCount: this.marketMin,
        salesRequire: this.chooseMarket,
        strategyId: this.strategyId,
        taskCount: this.taskMin,
        taskPeriod: this.taskPeriod,
        taskRequire: this.chooseTask,
        todayRideCount: this.ridingTodayMin,
        todayRideRequire: this.chooseRidingTodayMin,
        todayUserCount: this.peopleTodayMin,
        todayUserRequire: this.choosePeopleTodayMin,
        totalRideCount: this.ridingTotalMin,
        totalRideRequire: this.chooseRidingTotalMin,
        totalUserCount: this.peopleTotalMin,
        totalUserRequire: this.choosePeopleTotalMin,
        carCountRequire: this.carCountRequire,
        carCount: this.carCount,
        timeDayRequire: this.timeDayRequire,
        timeDayCount: this.timeDayCount,
        timeHourRequire: this.timeHourRequire,
        timeHourCount: this.timeHourCount
      };
      if (this.policyId) {
        params.policyId = this.policyId;
        modifyPolicyValue(params).then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("修改成功");
            this.$router.go(-1);
          }
        });
      } else {
        addPolicyValue(params).then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("创建成功");
            this.$router.go(-1);
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
  margin-right: 40px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.row {
  display: flex;
}

/deep/.ant-input-number {
  width: 176px;
  margin-right: 24px;
}
/deep/.ant-select-selection {
  width: 178px;
}
/deep/.ant-col {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
/deep/.ant-checkbox-wrapper {
  display: inline-flex;
  align-items: center;
}
/deep/.ant-checkbox + span {
  display: flex;
  align-items: center;
}
.rule__title {
  margin: 12px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.rule__hint {
  color: #999999;
  font-size: 12px;
}
.rule__detail {
  display: inline-block;
  width: 125px;
}
.rule__category {
  display: inline-block;
  margin-right: 12px;
  white-space: nowrap;
}
.footer {
  margin-top: 24px;
}
.footer button {
  margin-right: 12px;
}
.fixation__width {
  display: inline-block;
  width: 152px;
}
/deep/.ant-select {
  width: auto;
}
.rule__radio {
  margin-bottom: 24px;
}
.item__left {
  margin-right: 24px;
}
.item__left span {
  margin: 0 24px;
}
.item__left,
.item__right {
  display: flex;
  align-items: center;
}
.important {
  color: rgb(252, 13, 27);
  font-weight: bold;
}
.row {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.item__addIcon {
  color: #3769fc;
  display: flex;
  align-items: center;
}
.addIcon {
  font-size: 24px;
  margin-right: 12px;
}
.red__tag {
  color: rgb(252, 88, 92);
}
</style>
